<template lang="">
    <div>
        <div id="paymentBrick" style="margin: 16px;"></div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import { loadMercadoPago } from "@mercadopago/sdk-js";
        /* eslint-disable */

const settings = {
   initialization: {
     /*
      "amount" é o valor total a ser pago por todos os meios de pagamento
    com exceção da Conta Mercado Pago e Parcelamento sem cartão de crédito, que tem seu valor de processamento determinado no backend através do "preferenceId"
     */
     amount: 100,
   },
   customization: {
     paymentMethods: {
       //ticket: "all",
       bankTransfer: "all",
       creditCard: "all",
       // debitCard: "all",
       //mercadoPago: "all",
     },
     visual: {
        defaultPaymentOption: {
            bankTransferForm: true,
        },
        style: {
            theme: 'default',
        },
     }
   },
   callbacks: {
     onReady: () => {
       /*
        Callback chamado quando o Brick estiver pronto.
        Aqui você pode ocultar loadings do seu site, por exemplo.
       */
     },
     onSubmit: ({ selectedPaymentMethod, formData }) => {
       // callback chamado ao clicar no botão de submissão dos dados
       return new Promise((resolve, reject) => {
         fetch("http://localhost:8012/v1/checkout/40/11", {
           method: "POST",
           headers: {
             "Content-Type": "application/json",
            "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjgxNjU5NDc2LCJzdWIiOiIwODdjZjY5My0yYWExLTQ4NTAtYmM4My02ODA3MDRmMjNkMzEiLCJlbWFpbCI6ImxmdGlzc290QGdtYWlsLmNvbSIsInBob25lIjoiIiwiYXBwX21ldGFkYXRhIjp7InByb3ZpZGVyIjoiZW1haWwiLCJwcm92aWRlcnMiOlsiZW1haWwiLCJnb29nbGUiXX0sInVzZXJfbWV0YWRhdGEiOnsiYXZhdGFyX3VybCI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FHTm15eFk3T0pEbExQVDJVWmEycHlsVVRUM1hwOXJVT1o0b0NzdDBTWWJyaUE9czk2LWMiLCJkaXNwbGF5X25hbWUiOiJMdWlzIEZlbGlwZSBUaXNzb3QiLCJlbWFpbCI6ImxmdGlzc290QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmdWxsX25hbWUiOiJMdWlzIEZlbGlwZSBUaXNzb3QiLCJpc3MiOiJodHRwczovL3d3dy5nb29nbGVhcGlzLmNvbS91c2VyaW5mby92Mi9tZSIsIm5hbWUiOiJMdWlzIEZlbGlwZSBUaXNzb3QiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUdObXl4WTdPSkRsTFBUMlVaYTJweWxVVFQzWHA5clVPWjRvQ3N0MFNZYnJpQT1zOTYtYyIsInByb3ZpZGVyX2lkIjoiMTE3Nzg2MDI2NjU0NTU2ODU4NTc0Iiwic3ViIjoiMTE3Nzg2MDI2NjU0NTU2ODU4NTc0IiwidGVtcGxlU2V0IjoyNzQsIndlbGNvbWUiOnRydWV9LCJyb2xlIjoiYXV0aGVudGljYXRlZCIsImFhbCI6ImFhbDEiLCJhbXIiOlt7Im1ldGhvZCI6InBhc3N3b3JkIiwidGltZXN0YW1wIjoxNjgxNTkwMzkxfV0sInNlc3Npb25faWQiOiI1NWI0Y2JhZS1kZjJlLTQ2N2YtYTJlOS03YWY0NTcwNGJlNWEifQ.NXBywz0HDRFa2te8MSkH-aRhe7V2rNoinsFAN5_KM2k",
           },
           body: JSON.stringify({paymentMethod: 'card', extraData: formData}),
         })
           .then((response) => response.json())
           .then((response) => {
             // receber o resultado do pagamento
             resolve();
           })
           .catch((error) => {
             // lidar com a resposta de erro ao tentar criar o pagamento
             reject();
           });
       });
     },
     onError: (error) => {
       // callback chamado para todos os casos de erro do Brick
       console.error(error);
     },
   },
 };

export default {
    name: 'TestView',
    data() {
        return {
            scanner: null,
        }
    },
    methods: {
        ...mapActions('events', ['queryUpcomingEvents']),
        start() {
            this.scanner.start();
        }
    },
    async mounted() {
        await loadMercadoPago();
        const mp = new window.MercadoPago(process.env.MP_PUBLIC_KEY, {
            locale: "pt-BR",
        });
        const bricksBuilder = mp.bricks();
        let cardPaymentBrickController = await bricksBuilder.create('payment', 'paymentBrick', settings);
        mp.renderPaymentBrick(bricksBuilder);
    }
}
</script>
<style lang="">
    
</style>