<template lang="">
    <div class="checkin-container">
        <h1>Está na hora de fazer o Check-in para {{event.title}}!</h1>
        Ao chegar no evento, mostre esse QR Code na entrada.
        <img v-if="qrUrl" :src="qrUrl" />
        <code v-if="code">Código para uso manual: <b>{{ registration.id.substr(0,4).toUpperCase() }}-{{ registration.id.substr(4,4).toUpperCase() }}</b></code>
        <br>
    </div>
</template>
<script>
import QRCode from 'qrcode'
import Supabase from '@/supabaseInit';

export default {
    name: "CheckoutView",
    props: ['event', 'registration'],
    async mounted() {
        setTimeout(() => this.code = true, 15000);
        this.qrUrl = await QRCode.toDataURL('checkin:' + this.registration.id, { errorCorrectionLevel: 'L' });
        this.listener = Supabase
            .channel('schema-db-changes')
            .on(
                'postgres_changes',
                {
                    event: 'INSERT',
                    schema: 'public',
                },
                (data) => this.handleMessage(data)
            )
            .subscribe();
    },
    data() {
        return {
            qrUrl: null,
            listener: null,
            code: false,
        }
    },
    async beforeUnmount() {
        await this.listener.unsubscribe();
    },
    methods: {
        async handleMessage(message) {
            if (message.table == 'checkins' && message.eventType == 'INSERT') {
                await this.listener.unsubscribe();
                this.listener == null;
                this.$emit('checkedin');
            }
        }    
    },
}   
</script>
<style lang="scss" scoped>
img {
    width: 100%;
}
h1 {
    color: #333;
    line-height: 30px;
    font-size: 28px;
    font-weight: 700;
  }
  .checkin-container {
    padding: 30px;
    text-align: left;
  }
    
</style>