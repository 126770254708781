<template lang="">
    <div>
        <q-banner style="text-align: left;" class="bg-green text-white" v-if="confirmation.status == 'paid'">
            <template v-slot:avatar>
                <font-awesome-icon :icon="['fa','thumbs-up']" style="padding-right: 16px;"/>
            </template>
            <b>Meu pagamento foi bem sucedido. E agora?</b><br>
            Agora basta esperar o dia do evento e abrir a UMADC Digital para mostrar seu ingresso.
        </q-banner>
        <br>
        <div>Método de pagamento: <b>{{humanPaymentMethod}}</b></div>
        <div>Status: <q-badge rounded v-bind="statusBadgeProps" style="padding: 4px 8px;" /></div>
        <br>
        <component :is="getPaymentDetailComponent" :confirmation="confirmation" />
    </div>
</template>
<script>
import CashDetailsVue from './CashDetails.vue';
import FileDetailsVue from './FileDetails.vue';
import MPDetailsVue from './MPDetails.vue';
import PixDetailsVue from './PixDetails.vue';

export default {
    name: 'RegistrationConfirmation',
    props: ['confirmation'],
    computed: {
        getPaymentDetailComponent() {
            switch(this.confirmation.strategy) {
                case 'open':
                case 'mp_card':
                case 'mp_pix':
                    return MPDetailsVue;
                case 'file':
                    return FileDetailsVue;
                case 'cash':
                    return CashDetailsVue;
            }            
            return PixDetailsVue;
        },
        statusBadgeProps() {
            switch(this.confirmation.status) {
                case 'paid':
                    return {color: 'green', 'label': 'Pago'}
                case 'analysis':
                    return {color: 'blue', 'label': 'Em análise'};
                case 'pending':
                    return {color: 'blue', 'label': 'Pendente'};
                case 'expired':
                    return {color: 'red', 'label': 'Expirado'}
                case 'rejected':
                    return {color: 'red', 'label': 'Rejeitado'}
            }

            return {color: 'blue', 'label': 'Em processamento...'}
        },
        humanPaymentMethod() {
            switch(this.confirmation.strategy) {
                case 'mp_card':
                    return 'Cartão de crédito';
                case 'mp_pix':
                    return 'PIX';
                case 'file':
                    return 'Envio de comprovante';
                case 'cash':
                    return 'Dinheiro';
                default:
                    return "PM";
            }
        }
    }
}
</script>
<style lang="">
    
</style>