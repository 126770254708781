<template lang="">
    <div>
        <component :is="componentType" :confirmation="confirmation" />
    </div>
</template>

<script>
    import AdminCashConfirmationDetail from './AdminCashConfirmationDetail.vue';
    import AdminFileConfirmationDetail from './AdminFileConfirmationDetail.vue';
    import AdminOnlineConfirmationDetail from './AdminOnlineConfirmationDetail.vue';

    export default {
        name: 'AdminConfirmationDetail',
        props: ['confirmation'],
        computed: {
            componentType() {
                switch(this.confirmation.strategy) {
                    case 'cash':
                        return AdminCashConfirmationDetail;
                    case 'file':
                        return AdminFileConfirmationDetail;
                    case 'mp_pix':
                    case 'mp_card':
                        return AdminOnlineConfirmationDetail;
                }
                return 'aaa';
            }
        }
    }
</script>

<style lang="">
    
</style>