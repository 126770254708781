import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AppBadge from '@/components/AppBadge';
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCalendar, faClock, faLocationDot, faDoorOpen, faEdit, faCirclePlus, faList, faDoorClosed, faClose, faFire, faSpinner, faFile, faFilter, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import GoogleMapsAutocomplete from '@/components/GoogleMapsAutocomplete';
import MercadoPagoSvc from './services/MercadoPagoSvc';

const app = createApp(App).use(Quasar, quasarUserOptions).use(store).use(router);

app.config.globalProperties.mercadoPago = new MercadoPagoSvc();

library.add(faGoogle, faApple, faLocationDot, faCalendar, faClock, faDoorOpen, faEdit, faCirclePlus, faList, faDoorClosed, faClose, faFire, faSpinner, faFile, faFilter, faThumbsUp)
app.component('font-awesome-icon', FontAwesomeIcon)

app.component('AppBadge', AppBadge);
app.component('GoogleMapsAutocomplete', GoogleMapsAutocomplete);

app.mount('#app');