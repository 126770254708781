<template lang="">
    <div id="animation-container">
        {{authenticatedUser.user_metadata}}
        <span id="p1" v-if="authenticatedUser?.name" :class="{'transp': revealStep < 1}">
            {{(authenticatedUser?.name).split(" ")[0] }},
        </span>
        <span id="p1" :class="{'transp': revealStep < 2}">
            seja bem-vindo(a) à
        </span>
        <span id="p2" :class="{'transp': revealStep < 2}">
            UMADC Digital
        </span>
        <span id="p3"  :class="{'transp': revealStep < 3}">
            Por aqui você poderá saber de <b>todos os eventos</b> da UMADC Geral e das regionais, <b>fazer inscrição</b> nas atividades e <b>receber o material didático.</b><br> Tudo em um só lugar!
        </span>
        <div id="p4" v-if="! hasUser" :class="{'transp': revealStep < 4}">
            Mas antes, precisamos saber seu WhatsApp e qual congregação você é membro:
            <q-form @submit="vamoLa()">
                <q-input :rules="[required]" v-model="phone" label="WhatsApp" mask="(##) #####-####" />
                <q-select
                    v-model="congregacao"
                    behavior="menu"
                    option-value="id"
                    option-label="name"
                    label="Congregação"
                    :options="filteredTemples"
                    use-input
                    :rules="[required]"
                    :disable="fromOtherChurch"
                    v-if="! fromOtherChurch"
                    @filter="filterTemples"
                ></q-select>
                <q-checkbox v-model="fromOtherChurch" size="sm" label="Sou de outra igreja" />
                <q-input 
                    v-model="otherChurch"
                    ref="otherChurch"
                    :rules="[required]"
                    v-if="fromOtherChurch"
                    label="Sua igreja"/>
                <q-btn :class="{'transp': revealStep < 4}" outline :loading="loading" type="submit" :disable="letsGoButtonDisable" label="Salvar e avançar &raquo;" id="btn"/>
            </q-form>
        </div>
        <q-btn v-if="hasUser" :class="{'transp': revealStep < 4}" outline @click="vamoLa()" label="Vamos lá? &raquo;" id="btn"/>
    </div>
</template>
<script>
import ValidationMixin from '@/mixins/ValidationMixin';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import supabaseInit from '../supabaseInit';

export default {
    mixins: [ValidationMixin],
    data() {
        return {
            revealStep: 0,
            user: null,
            fromOtherChurch: false,
            otherChurch: null,
            filteredTemples: [],
            congregacao: null,
            loading: false,
            phone: null,
        };
    },
    methods: {
        async vamoLa() {
            const updateData = {
                congregacao: this.congregacao,
                fromOtherChurch: this.fromOtherChurch,
                otherChurch: this.otherChurch,
                phone: this.phone,
            };
            this.loading = true;
            await axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/v1/welcome`,
                updateData,
                { headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'application/json' } }
            );
            await supabaseInit.auth.refreshSession();
            this.$router.push(this.$route.query.redirectTo ?? '/');
        },
        filterTemples(value, update) {
            if (value == '') {
                update(() => this.filteredTemples = this.temples);
                return;
            }
            update(() => this.filteredTemples = this.temples.filter((temple) => temple.name.toLowerCase().indexOf(value.toLowerCase()) > -1));
        },
        ...mapActions(['queryTemples']),
    },
    async mounted() {
        if (! this.hasUser) {
            this.queryTemples();
        }
        setTimeout(() => this.revealStep++, 1);
        setTimeout(() => this.revealStep++, 750);
        setTimeout(() => this.revealStep++, 2000);
        setTimeout(() => this.revealStep++, 4000);
    },
    computed: {
        ...mapGetters(['temples']),
        ...mapGetters('auth', ['authenticatedUser']),
        hasUser() {
            return !!this.authenticatedUser?.user_metadata?.hasUser;
        },
        letsGoButtonLabel() {
            return this.hasUser ? 'Vamos lá?' : 'Salvar';
        },
        letsGoButtonDisable() {
            return !this.hasUser && !this.congregacao && !this.otherChurch;
        }
    },
    watch: {
        fromOtherChurch(val) {
            setTimeout(() => val && this.$refs.otherChurch.focus(), 100);
            val ? this.congregacao = null : this.otherChurch = null;
        }
    }
}
</script>
<style lang="scss" scoped>
* {
    transition: 1s all;
}

#animation-container {
    height: 100vh;
    width: 100vw;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    padding: 30px;
}

span {
    text-align: left;
    background: linear-gradient(100deg, rgb(131, 255, 224) 0%, rgb(149, 202, 255) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#p1 {
    font-size: 24px;
    letter-spacing: -2px;
    font-weight: 700;
    line-height: 24px;
}

#p2 {
    position: relative;
    top: -12px;
    font-size: 42px;
    letter-spacing: -3px;
    font-weight: 700;
    line-height: 60px;
    background: linear-gradient(100deg, rgb(189, 255, 239) 0%, rgb(207, 231, 255) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#p3 {
    font-size: 16px;
}

#p4 {
    text-align: left;
    margin-top: 24px;
}

b {
    background: linear-gradient(100deg, rgb(189, 255, 239) 0%, rgb(207, 231, 255) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#btn {
    margin-top: 30px;
    width: 100%;
}

.transp {
    opacity: 0 !important;
}</style>