<template lang="">
    <div>
        <div class="scanner-container">
            <q-btn label="Iniciar checkin" @click="start" />
        </div>
        <video ref="view"></video>
    </div>
</template>
<script>
import NotifyMixin from '@/mixins/NotifyMixin';
import QrScanner from 'qr-scanner';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
    name: 'CheckinScannerView',
    mixins: [NotifyMixin],
    data() {
        return {
            scanner: null,
            code: false,
            timeout: null,
        }
    },
    methods: {
        start() {
            this.code = false;
            setTimeout(() => this.scanner.start(), 1);
            this.timeout = setTimeout(() => this.scanTimedOut(), 15000);
        },
        scanTimedOut() {
            this.code = true;
            this.scanner.stop();
            this.$q.notify({
                type: 'negative',
                position: 'top',
                message: "Não foi possível fazer o checkin via QR code. Tente através do código.",
            });
        },
        async handleQrCode(result) {
            this.scanner.stop();
            clearTimeout(this.timeout);
            await axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/v1/admin/checkin`,
                {'scanned': result.data},
                { headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'application/json' } }
            );
        },
    },
    async mounted() {
        this.scanner = new QrScanner(
            this.$refs.view,
            result => this.handleQrCode(result),
            { maxScansPerSecond: 4 },
        );
    },
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
    }
}
</script>
<style lang="scss" scoped>
    video {
        width: 100%;
        border-radius: 30px;
        background-color: #eee;
    }

    .scanner-container {
        padding: 30px;
    }
</style>