<template lang="">
    <div v-if="confirmation">
        <div class="button-container" v-if="confirmation.status != 'paid' && confirmation.status != 'rejected'">
            <q-btn label="Rejeitar" @click="denyConfirmation(confirmation.id)" color="red"/>
            <q-btn label="Aprovar" @click="approveConfirmation(confirmation.id)" color="green"/>
        </div>
    </div>
</template>
<script>
import UpdatesConfirmations from '@/mixins/UpdatesConfirmations';

export default {
    mixins: [UpdatesConfirmations],
    props: ['confirmation']
}
</script>
<style lang="scss" scoped>
    .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
</style>