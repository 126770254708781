<template lang="">
    <div>
                <q-stepper
                v-model="step"
                vertical
                flat
                color="primary"
                animated
                style="text-align: left;"
                >
                <q-step
                    :name="1"
                    title="Como funciona?"
                    :done="step > 1"
                >{{sending}}
                O método de pagamento "Envio de arquivo" permite enviar um comprovante de pagamento para ser analisado pela equipe. É importante lembrar que a validação pode levar tempo e que o comprovante deve atender aos critérios de validação para ser aceito.
                    <q-stepper-navigation>
                    <q-btn @click="sending = true" color="primary" label="Vamos lá! &raquo;" />
                    </q-stepper-navigation>
                </q-step>

                <q-step
                    :name="2"
                    title="Envio do arquivo"
                    :done="step > 2"
                >
                        <input @change="receiptAdded($event)" id="fileInput" type="file"/><br><br>
                        <q-btn @click="submitReceipt" :loading="loading" label="Enviar comprovante" :disabled="! receipt"/>
                </q-step>

                <q-step
                    :name="3"
                    title="Em análise"
                    :done="step > 3"
                >
                    Recebemos o comprovante de pagamento que você enviou através do método "Envio de arquivo". Nossa equipe está analisando o documento e em breve daremos retorno!<br>
                    <br>
                    Por favor, esteja ciente de que a validação do comprovante pode levar algum tempo.

                </q-step>

                <q-step
                    :name="4"
                    title="Resultado"
                    :done="step > 4"
                >
                    <div v-if="confirmation.status == 'rejected'">
                        Seu comprovante foi rejeitado pela seguinte razão: <b>{{confirmation.response_data?.reason}}</b><br><br>
                        Inicie uma nova inscrição para enviar novo arquivo.
                    </div>
                    <div v-else>
                        Comprovante <b>aceito</b>!
                    </div>
                </q-step>

            </q-stepper>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import NotifyMixin from '@/mixins/NotifyMixin';

export default {
    name: 'FileDetails',
    props: ['confirmation'],
    mixins: [NotifyMixin],
    data() {
        return {
            receipt: null,
            sending: false,
            loading: false,
        }
    },
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
        step() {
            if (this.sending) return 2;
            switch(this.confirmation.status) {
                case 'pending':
                    return 1;
                case 'analysis':
                    return 3;
                case 'rejected':
                case 'paid':
                    return 4;
            }
            return 1;
        },
    },
    methods: {
        ...mapActions('registrations', ['updateSingleRegistration']),
        receiptAdded(event) {
            if (!event) return;
            this.receipt = event.target.files[0];
        },
        async submitReceipt() {
            if (!this.receipt) return;

            const formData = new FormData();
            formData.append('receipt', this.receipt);

            this.loading = true;            
            axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/v1/confirmation/${this.confirmation.id}/receipt`,
                formData,
                { headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'multipart/form-data' } }
            )
                .then(() => {
                    this.updateSingleRegistration(this.confirmation.registration_id);
                    this.sending = false;
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                });
        },
    },
}
</script>
<style lang="">
    
</style>