<template>
    <div style="display: flex; justify-content: center; width:100vw;">
        <div style="margin: 0; width: 100%; max-width: 700px; text-align: center;">
            <app-header />
            <app-menu />
            <div class="app-container" :class="{'menu-open': isMenuVisible}">
                <router-view/>
            </div>
            <app-overlay />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppHeader from '@/components/AppHeader.vue'
import AppMenu from '@/components/AppMenu.vue'
import AppOverlay from '@/components/AppOverlay.vue'
import { useQuasar } from 'quasar'

export default {
  name: 'MainLayout',
  components: {AppHeader, AppMenu, AppOverlay},
  methods: {
    ...mapActions(['hideOverlay']),
    ...mapActions('auth', ['setAuthenticatedUser']),
  },
  computed: {
    ...mapGetters(['isOverlayVisible', 'getOverlay', 'isMenuVisible']),
  },
  async mounted() {
    const $q = useQuasar();
    $q.dark.set(false);
  },
}
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500;700;900&display=swap');

    body, body.body--dark {
        overflow-x: hidden;
        font-family: Poppins;
        margin: 0;
        background: rgb(0,201,150);
        background: linear-gradient(100deg, rgba(0,201,150,1) 0%, rgba(0,157,255,1) 100%) !important;
        color: #333;
    }

    .app-container {
        margin: 0;
        width: 100%;
        max-width: 700px;
        background-color: #eee;
        border-radius: 60px 60px 0px 0px;
        text-align: left;
        min-height: calc(100vh - 80px);
        position: absolute;
        top: 70px;
        transition: all 0.33s;
        transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
        transform: scale(1);
        height: 100vh;
        
        &.menu-open {
            transition: all 0.33s;
            top: 220px;
            transform: scale(0.9);
        }
    }

    .clickable {
        cursor: pointer;
    }
    
</style>
