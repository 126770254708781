<template lang="">
    <div>
        <!-- <div class="header" :style="headerStyle">
            <span class="title">{{ updatedEvent?.title }}</span>
            <span v-if="updatedEvent.speakers" class="speakers">{{stringStartDate}}</span>
        </div> -->
        <EventFilesComponent v-if="event" :files="event.files" />
    </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import EventFilesComponent from '@/components/events/EventFilesComponent.vue';
export default {
    components: {EventFilesComponent},
    data() {
        return {
            id: null,
            event: null,
        }
    },
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
        headerStyle() {
            return `background-image: linear-gradient(0deg, rgba(0, 50, 100, 0.7), rgba(0, 50, 100, 0.7)) ,url('https://storage.cloud.google.com/umadc-digital-public/${this.updatedEvent?.image}')`;
        },
    },
    async mounted() {
        this.id = this.$route.params.id;
        const { data } = await axios.get(
            `${process.env.VUE_APP_BACKEND_URL}/v1/events/${this.id}`,
            {
                headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'application/json' }
            }
        );

        this.event = data;
    }
}
</script>
<style lang="scss" scoped></style>