<template lang="">
    <div class="app-header">
        <div @click="toggleMenu()">
            <i v-if="! hideMenu" class="fa fa-bars fa-lg clickable"/>
        </div>
        <img src="@/assets/img/umadc-digital.png" style="height: 40px;"/>
        <div>
            <img @click="viewProfile()" v-if="! hideProfile && authenticatedUser" class="profile" referrerpolicy="no-referrer" :src="authenticatedUser?.image ?? fallbackProfilePic"/>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: "AppHeader",
    methods: {
        ...mapActions(['toggleMenu']),
        viewProfile() {
            this.$router.push("/profile");
        },
    },
    props: {
        hideMenu: {
            type: Boolean,
            default: false,
        },
        hideProfile: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
        fallbackProfilePic() {
            return "https://ui-avatars.com/api/?background=0D8ABC&color=fff&name="+this.authenticatedUser?.name;
        }
    }
}
</script>
<style lang="scss" scoped>
    .app-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px 16px 8px 16px;
        color: white;

        .profile {
            border-radius: 25px;
            height: 40px;
        }

        div {
            width: 50px;
        }
    }
</style>