<template>
    <div class="signup">
        <img src="@/assets/img/umadc-digital.png" />
        <div id="signup-form">
            <span :class="{ 'transp': revealStep < 1 }">
                O primeiro passo na UMADC Digital é criar sua conta...<br>
            </span>
            <span :class="{ 'transp': revealStep < 2 }">
                <strong>Vamos começar?!</strong>
            </span>
            <q-form :class="{ 'transp': revealStep < 3 }" id="form" @submit="createAccount">
                <q-input :rules="[required, fullName]" v-model="nome" label="Nome completo" />
                <q-select v-model="congregacao" behavior="menu" option-value="id" option-label="name" label="Congregação"
                    :options="filteredTemples" use-input :rules="[required]" :disable="fromOtherChurch"
                    v-if="!fromOtherChurch" @filter="filterTemples"></q-select>
                <q-checkbox v-model="fromOtherChurch" size="sm" dark label="Sou de outra igreja" />
                <q-input v-model="otherChurch" ref="otherChurch" :rules="[required]" v-if="fromOtherChurch"
                    label="Sua igreja" />
                <q-input :rules="[required, emailRule]" v-model="email" label="Email" />
                <q-input :rules="[required]" v-model="phone" label="Telefone celular" mask="(##) #####-####" />
                <q-input :rules="[required, minRule(6)]" v-model="pwd" type="password" label="Senha" />
                <q-input :rules="[required, pwdMatch(pwd)]" v-model="pwd2" type="password" label="Confirmar Senha" />
                <q-btn outline :loading="loading" label="Entrar &raquo;" type="submit"
                    style="margin-top: 16px; float: right;" /><br>
            </q-form>
        </div>
    </div>
</template>

<script>
import ValidationMixin from '@/mixins/ValidationMixin';
import * as Yup from 'yup';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

const signUpSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().min(6).required(),
    password_confirm: Yup.string().min(6).oneOf([Yup.ref('password'), null], 'Passwords must match').required(),
    phone: Yup.string().required(),
    temple_id: Yup.string().when('other_church', {
        is: (val) => val != null,
        then: (schema) => schema,
        otherwise: (schema) => schema.required(),
    }),
    other_church: Yup.string().when('temple_id', {
        is: (val) => val != null,
        then: (schema) => schema,
        otherwise: (schema) => schema.required(),
    }),
}, [["temple_id", "other_church"]]);

signUpSchema;

export default {
    name: 'SignUpView',
    mixins: [ValidationMixin],
    data() {
        return {
            nome: null,
            email: this.$route.query.email,
            pwd: null,
            pwd2: null,
            congregacao: null,
            revealStep: 0,
            fromOtherChurch: false,
            otherChurch: null,
            filteredTemples: [],
            loading: false,
            phone: null,
        }
    },
    methods: {
        ...mapActions(['queryTemples']),
        async createAccount() {
            this.loading = true;
            const firstName = this.nome.split(" ").shift();
            const emailRedirectTo = new URL(process.env.VUE_APP_URL + "/auth/welcome");

            if (firstName)
                emailRedirectTo.searchParams.append("name", firstName);

            if (this.$route.query.redirectTo)
                emailRedirectTo.searchParams.append("redirectTo", this.$route.query.redirectTo);

            const signUpData = {
                email: this.email,
                password: this.pwd,
                congregacao: this.congregacao ? this.congregacao.id : null,
                otherChurch: !this.congregacao ? this.otherChurch : null,
                phone: this.phone,
                options: {
                    emailRedirectTo: emailRedirectTo,
                    data: {
                        display_name: this.nome,
                    }
                }
            };

            await axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/signup`,
                signUpData
            );

            this.$router.push({ path: `/auth/confirmation`, query: { name: firstName } });
        },
        async hash(email) {
            var buffer = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(email));
            var chars = Array.prototype.map.call(new Uint8Array(buffer), ch => String.fromCharCode(ch)).join('');
            return btoa(chars);
        },
        filterTemples(value, update) {
            if (value == '') {
                update(() => this.filteredTemples = this.temples);
                return;
            }
            update(() => this.filteredTemples = this.temples.filter((temple) => temple.name.toLowerCase().indexOf(value.toLowerCase()) > -1));
        },
    },
    mounted() {
        setTimeout(() => this.revealStep++, 100);
        setTimeout(() => this.revealStep++, 2000);
        setTimeout(() => this.revealStep++, 4000);
        this.queryTemples();
    },
    computed: {
        ...mapGetters(['temples'])
    },
    watch: {
        fromOtherChurch(val) {
            setTimeout(() => val && this.$refs.otherChurch.focus(), 100);
            val ? this.congregacao = null : this.otherChurch = null;
        }
    }
}
</script>

<style lang="scss" scoped>
.signup {
    transition: 1s all;
    padding: 30px;
    width: 100vw;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 48px;
    text-align: left;

    img {
        display: inline;
        width: 33vw;
        max-width: 200px;
    }

    #signup-form {
        width: 80vw;
        max-width: 500px;
    }

    span {
        font-weight: 300;
        letter-spacing: -1px;
        font-size: 20px;
        padding-bottom: 40px;
        opacity: 1;
        transition: 1s all;
    }
}

#form {
    opacity: 1;
    transition: 1s all;
}

.transp {
    opacity: 0 !important;
}
</style>
