<template lang="">
    <div class="speaker">
        <img :src="profilePic"/>
        <div class="text-detail">
            <span class="name">
                {{ speaker.name }}
            </span>
            <span class="detail">
                {{ speaker.details }}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: "speaker",
    props: {
        speaker: {
            type: Object,
            required: true,
        }
    },
    computed: {
        profilePic() {
            if (! this.speaker.image) {
                return "https://ui-avatars.com/api/?background=0D8ABC&color=fff&name="+this.speaker?.name;
            }
            
            return `https://storage.cloud.google.com/umadc-digital-public/${this.speaker.image}`;
        }
    }
}
</script>
<style lang="scss" scoped>
    .speaker {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;

        img {
            height: 40px;
            border-radius: 40px;
        }

        .text-detail {
            display: flex;
            flex-direction: column;
            
            .name {
                height: 100%;
                font-weight: 500;
                font-size: 14px;
            }
    
            .detail {
                height: 100%;
                font-weight: 300;
                font-size: 12px;
                line-height: 11px;
                color: #999;
            }
        }
    }
</style>