import { mapActions, mapGetters } from "vuex";
import CheckinView from '@/views/checkin/CheckinView.vue';

export default {
    computed: {
        ...mapGetters('checkins', ['activeCheckins']),
        ...mapGetters('auth', ['authenticatedUser']),
    },
    methods: {
        ...mapGetters('registrations', ['getEventRegistration']),
        ...mapActions('checkins', ['queryActiveCheckins']),
        ...mapActions('registrations', ['queryRegistrations']),
        ...mapActions('events', ['updateSingleEvent']),
        ...mapActions(['showOverlay', 'hideOverlay']),
        async joinEvent(event) {
            await this.updateSingleEvent(event.id);
            if (! this.authenticatedUser) {
                this.showOverlay({'component': 'EventBody', props: {'event': event}, events: []});
                return;
            }
            
            await this.queryRegistrations();
            const eventRegistration = this.getEventRegistration(event.id)();

            if (eventRegistration == undefined) {
                this.showOverlay({'component': 'EventBody', props: {'event': event}, events: []});
                return;
            }
            
            await this.queryActiveCheckins();

            if (event.checkin_policy != 'none' && ! this.activeCheckins[event.id]) {
                this.showOverlay({
                    'component': CheckinView,
                    props: { 'event': event, 'registration': eventRegistration },
                    events: {checkedin: () => this.hideOverlay() && this.$router.push('/events/'+event.id)}
                });
            } else {
                this.$router.push('/events/'+event.id);
            }
        },
    }
}