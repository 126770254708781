import Supabase from '@/supabaseInit';
import moment from 'moment';
export default {
    namespaced: true,
    state: () => ({
        allEvents: [],
        allAdminEvents: [],
    }),
    mutations: {
        setAllEvents(state, events) {
            state.allEvents = events;
        },
        pushEvents(state, events) {
            const tempEvents = state.allEvents;
            state.allEvents = [];
            events.forEach((event) => {
                tempEvents[event.id] = event
            });
            state.allEvents = tempEvents;
        },
        setAllAdminEvents(state, events) {
            state.allAdminEvents = events;
        },
        updateSingleEvent(state, updatedEvent) {
            state.allEvents[updatedEvent.id] = updatedEvent;
        }
    },
    actions: {
        async queryUpcomingEvents({commit}) {
            const now = moment().toISOString();
            const future = moment().add(1, 'months').toISOString();
            
            let supabaseEvents = await Supabase.from('events')
                .select('id,title,description,details,image,starts_at,checkin_policy,checkin_starts_at,checkin_ends_at,tags(name,color)')
                .or(`or(and(starts_at.gt.${now},starts_at.lt.${future}),force_upcoming.eq.TRUE),and(starts_at.lt.${now},checkin_ends_at.gt.${now})`)
                .is('deleted_at', null)
                .is('tags.deleted_at', null)
                .is('parent_id', null)
                .order('starts_at', {ascending: true});

            commit('pushEvents', supabaseEvents.data);
        },
        async queryEventsForMonth({commit}, month) {
            let supabaseEvents = await Supabase.from('events')
                .select('id,title,description,details,image,starts_at,tags(name,color)')
                .gt('starts_at', moment().month(month).startOf('month').toISOString())
                .lt('starts_at', moment().month(month).endOf('month').toISOString())
                .is('deleted_at', null)
                .is('tags.deleted_at', null)
                .is('parent_id', null)
                .order('starts_at', {ascending: true});

            commit('pushEvents', supabaseEvents.data);
        },
        async queryAllAdminEvents({commit}) {
            Supabase.from('events')
                .select('id,title,starts_at')
                .is('deleted_at', null)
                .then(({data}) => commit('setAllAdminEvents', data));
        },
        async updateSingleEvent({commit}, id) {
            let updatedEvent = await Supabase
                .from('events')
                .select('id,title,description,details,image,starts_at,checkin_policy,checkin_starts_at,checkin_ends_at,registration_policy,allowed_payment_methods,tags(name,color),speakers(name,image,details),venues(name,address,image,lat,lng),pricing_tiers(*)')
                .is('tags.deleted_at', null)
                .is('speakers.deleted_at', null)
                .is('venues.deleted_at', null)
                .eq('id', id)
                .limit(1);

            commit('updateSingleEvent', updatedEvent.data[0]);
        }
    },
    getters: {
        getAllEvents(state) {
            return Object.values(state.allEvents).sort((eventA, eventB) => moment(eventA.starts_at) - moment(eventB.starts_at));
        },
        getAllAdminEvents(state) {
            return state.allAdminEvents;
        },
        getAllEventsWithTag: (state) => (tagFilter) => {
            return Object.values(state.allEvents).filter(
                (event) => event.tags.filter(
                    (tag) => tag.name.toLowerCase() == tagFilter.toLowerCase()
                ).length > 0 && moment(event.checkin_starts_at ?? event.starts_at).isAfter(moment.now())
            ).sort((eventA, eventB) => moment(eventA.starts_at) - moment(eventB.starts_at));
        },
        getRightNowEvents(state) {
            return Object.values(state.allEvents).filter(
                (event) => moment(event.checkin_starts_at).isBefore(moment.now()) && moment(event.checkin_ends_at).isAfter(moment.now())
            ).sort((eventA, eventB) => moment(eventA.starts_at) - moment(eventB.starts_at));
        },
        getSingleEvent: (state) => (eventId) => {
            return state.allEvents[eventId];
        },
    }
}