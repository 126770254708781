<template lang="">
    <div class="checkout-container">
        <h1>Você está a um passo de se inscrever em <span class="text-black">{{ updatedEvent?.title }}</span>!</h1>
        <q-stepper
        v-model="step"
        vertical
        animated
        flat
        color="#eee"
        >
            <q-step
                :name="1"
                title="Informações e método de pagamento"
                :done="step > 1"
            >
                <q-label>Inscrição</q-label>
                <PriceTier :tier="tier" :hide-cta="true" v-if="tier"/>
                <br>
                <div v-if="this.tier?.price > 0">
                    <q-label>Forma de pagamento</q-label>
                    <q-list>
                        <q-item v-if="updatedEvent.allowed_payment_methods.includes('mp_pix')" v-ripple>
                            <q-item-section avatar>
                                <q-radio v-model="paymentMethod" val="mp_pix" color="blue" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>PIX</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="updatedEvent.allowed_payment_methods.includes('mp_card')" v-ripple>
                            <q-item-section avatar>
                                <q-radio v-model="paymentMethod" val="mp_card" color="blue" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Cartão de Crédito</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="updatedEvent.allowed_payment_methods.includes('file')" v-ripple>
                            <q-item-section avatar>
                                <q-radio v-model="paymentMethod" val="file" color="blue" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Envio de comprovante</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item v-if="updatedEvent.allowed_payment_methods.includes('cash')" v-ripple>
                            <q-item-section avatar>
                                <q-radio v-model="paymentMethod" val="cash" color="blue" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Dinheiro</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </div>
                <q-separator style="margin: 16px 0px;" />
                <q-stepper-navigation>
                    <q-btn @click="step = 2" :disabled="! paymentMethod" outline color="primary" label="Próximo passo &raquo;" />
                </q-stepper-navigation>
            </q-step>

            <q-step
                :name="2"
                title="Efetuação do pagamento"
                :done="step > 2"
            >
                <span v-if="paymentMethod == 'cash'">
                    Para completar seu pagamento você deve levar o valor em dinheiro no dia do evento. Sua entrada só será permitida mediante pagamento.
                </span>

                <span v-else-if="paymentMethod == 'file'">
                    Para completar seu pagamento você deve enviar o comprovante de transferência no sistema. Nossa equipe irá validá-lo. Sua entrada só será permitida mediante a aprovação do pagamento.
                </span>

                <span v-else>
                    <div id="paymentBrick"></div>
                </span>
                <q-stepper-navigation>
                    <q-btn flat @click="step = 1" color="primary" label="&laquo; Voltar" class="q-ml-sm" />
                    <q-btn v-if="['cash', 'file'].includes(paymentMethod)" @click="() => {submit()}" color="primary" label="Ok, entendi! &raquo;" />
                </q-stepper-navigation>
            </q-step>

            <q-step
                :name="3"
                title="Sucesso!"
            >
                <MercadoPagoStatus v-if="mpId" :id="mpId" />
                <registration-confirmation v-else :confirmation="confirmation" />
            </q-step>
        </q-stepper>
    </div>
</template>
<script>
import ValidationMixin from '@/mixins/ValidationMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import { mapActions, mapGetters } from 'vuex';
import PriceTier from '@/components/PriceTier.vue';
import axios from 'axios';
import { buildMpPaymentConfig } from '@/configs/mpConfig';
import MercadoPagoStatus from '@/components/MercadoPagoStatus.vue';
import RegistrationConfirmation from '@/components/PaymentDetail/RegistrationConfirmation.vue';

export default {
    name: 'CheckoutView',
    mixins: [ValidationMixin, NotifyMixin],
    components: { PriceTier, MercadoPagoStatus, RegistrationConfirmation },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (vm.$store.getters['auth/authenticatedUser']) {
                return true;
            }
            vm.$router.push('/auth/login?reason=checkout&redirectTo=' + vm.$route.path + '');
            return false;
        });
    },
    async mounted() {
        await this.mercadoPago.init();
        this.updateSingleEvent(this.$route.params.eventId);
    },
    data() {
        return {
            cpf: null,
            paymentMethod: null,
            response: null,
            step: 1,
            mp: null,
            bricksBuilder: null,
            mpId: null,
            confirmation: null,
        };
    },
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
        ...mapGetters('events', ['getSingleEvent']),
        updatedEvent() {
            return this.$store.getters['events/getSingleEvent'](this.$route.params.eventId);
        },
        tier() {
            return this.updatedEvent?.pricing_tiers.find((tier) => tier.id == this.$route.params.tierId);
        },
        tierValue() {
            if (this.tier?.price == 0) {
                return 'GRÁTIS';
            }

            let Real = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            });

            return Real.format(this.tier?.price / 100);
        }
    },
    methods: {
        ...mapActions('events', ['updateSingleEvent']),
        ...mapActions('registrations', ['updateSingleRegistration']),
        async submit(transactionData = null) {
            const registrationData = {
                'paymentMethod': this.paymentMethod,
                'tierId': this.$route.params.tierId,
                'transactionData': transactionData
            };

            axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/v1/checkout/${this.tier.event_id}/${this.tier.id}`,
                registrationData,
                { headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'application/json' } }
            )
                .then(({ data }) => {
                    this.updateSingleRegistration(data[0].registration_id);
                    if (['mp_card', 'mp_pix'].includes(this.paymentMethod)) {
                        this.mpId = data[0].transaction_id;
                    } else {
                        debugger;
                        this.confirmation = data[0];
                    }
                    this.step = 3;
                })
                .catch(
                    ({ response: { data: { error } } }) => {
                        switch (error) {
                            case 'INVALID_DATA':
                                this.$q.notify({
                                    type: 'negative',
                                    message: "Dados inválidos",
                                    position: 'top',
                                }); break;
                            case 'INVALID_PAYMENT_METHOD':
                                this.$q.notify({
                                    type: 'negative',
                                    message: "Método de pagamento inválido",
                                    position: 'top',
                                }); break;
                            case 'TIER_UNAVAILABLE':
                                this.$q.notify({
                                    type: 'negative',
                                    message: "Opss... as inscrições desse lote esgotaram",
                                    position: 'top',
                                }); break;
                            case 'INVALID_TIER_OR_EVENT':
                                this.$q.notify({
                                    type: 'negative',
                                    message: "Lote ou evento inválido",
                                    position: 'top',
                                }); break;
                            case 'PIX_FAIL':
                                this.$q.notify({
                                    type: 'negative',
                                    message: "Ocorreu um erro ao processar o PIX. Tente novamente mais tarde!",
                                    position: 'top',
                                }); break;
                            case 'ALREADY_REGISTERED':
                                this.$q.notify({
                                    type: 'negative',
                                    message: "Você já tem uma inscrição para esse evento!",
                                    position: 'top',
                                }); break;
                            case 'Invalid card_number_validation':
                                this.$q.notify({
                                    type: 'negative',
                                    message: "Erro ao processar pagamento. Verifique os dados do cartão.",
                                    position: 'top',
                                }); this.step = 1; break;
                        }
                    }
                );
        }
    },
    watch: {
        async step(val) {
            if (val == 2 && ['mp_card', 'mp_pix'].includes(this.paymentMethod)) {
                await this.withLoader(async () => {
                    let config;
                    let bricksBuilder = this.mercadoPago.getBricksBuilder();
                    if (this.paymentMethod == 'mp_card') {
                        config = buildMpPaymentConfig(
                            parseFloat(this.tier.price / 100).toFixed(2),
                            this.paymentMethod,
                            this.authenticatedUser.email,
                            () => { },
                            async ({ formData }) => await this.submit(formData),
                            () => { }
                        )
                    } else if (this.paymentMethod == 'mp_pix') {
                        config = buildMpPaymentConfig(
                            parseFloat(this.tier.price / 100).toFixed(2),
                            this.paymentMethod,
                            this.authenticatedUser.email,
                            () => { },
                            async ({ formData }) => await this.submit(formData),
                            () => { }
                        )
                    }

                    try {
                        window.paymentBrickController.unmount()
                    } catch (e) {
                        //
                    }

                    window.paymentBrickController = await bricksBuilder.create('payment', 'paymentBrick', config);
                });
            } else {
                if (window.paymentBrickController) {
                    window.paymentBrickController.unmount()
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.checkout-container {
    padding: 30px;
}

#finaliza {
    width: 100%;
}

h1 {
    color: #333;
    line-height: 30px;
    font-size: 28px;
    font-weight: 700;
}
</style>