<template>
  <div style="padding-top: 40px">
    <h1>
      Próximos Eventos
      <font-awesome-icon v-if="can('write_events')" style="margin-left: 8px;" color="#1e88e5" @click="createEvent()" :icon="['fa', 'circle-plus']"/>
    </h1>
    <h2 v-if="getRightNowEvents.length > 0">Acontecendo agora</h2>
    <div class="horizontal-scroll" v-if="getRightNowEvents.length > 0">
    <card
      v-for="event in getRightNowEvents"
      :key="event.id"
      @click="joinEvent(event)"
      :image="event.image">
        <template v-slot:body>
          <EventTitle
          :starts-at="event.starts_at"
          :title="event.title"
          :tags="event.tags"
          :details="event.details"/>
        </template>
      </card>
    </div>
    <h2>Geral</h2>
    <div class="horizontal-scroll" v-if="getAllEventsWithTag('geral').length > 0">
      <card
        v-for="event in getAllEventsWithTag('geral')"
        :key="event.id"
        @click="showEventDetails(event)"
        :image="event.image">
        <template v-slot:body>
          <EventTitle
          :starts-at="event.starts_at"
          :title="event.title"
          :tags="event.tags"
          :details="event.details"/>
        </template>
      </card>
    </div>
    <div class="text-grey text-center" v-else>
      Sem eventos por enquanto...
    </div>
    <h2>Regionais</h2>
      <div id="regionais"  v-if="getAllEventsWithTag('regional').length > 0">
        <card
        v-for="event in getAllEventsWithTag('regional')"
        :key="event.id"
        @click="showEventDetails(event)">
          <template v-slot:body>
          <EventTitle
            :starts-at="event.starts_at"
            :title="event.title"
            :tags="event.tags"
            :details="event.details"
              :extra-padding="true"/>
          </template>
        </card>
      </div>
      <div class="text-grey text-center" v-else>
        Sem eventos por enquanto...
      </div>
  </div>
</template>

<script>
import EventTitle from "@/components/EventTitle.vue"
import Card from '@/components/Card.vue';
import { mapActions, mapGetters } from 'vuex';
import AuthMixin from '@/mixins/AuthMixin';
import EventJoinerMixin from '@/mixins/EventJoinerMixin';

export default {
  components: { EventTitle, Card },
  name: 'HomeView',
  mixins: [
    AuthMixin,
    EventJoinerMixin,
  ],
  methods: {
    showEventDetails(event) {
      this.updateSingleEvent(event.id);
      this.showOverlay({'component': 'EventBody', props: {'event': event}, events: []});
    },
    getAllEventsWithTag(tag) {
      return this.$store.getters['events/getAllEventsWithTag'](tag);
    },
    createEvent() {
      this.$router.push('/admin/events/new');
    },
    ...mapActions(['showOverlay']),
    ...mapActions('events', ['updateSingleEvent']),
  },
  data() {
    return {
    }
  },
  computed: {
    getAllEvents() {
      return this.$store.getters['events/getAllEvents'];
    },
    ...mapGetters('events', ['getRightNowEvents'])
  },
  mounted() {
    this.$store.dispatch('events/queryUpcomingEvents')
  }
}
</script>

<style lang="scss" scoped>
  h1 {
    color: #333;
    margin: 0px 30px;
    line-height: 30px;
    font-size: 28px;
    font-weight: 700;
  }

  h2 {
    font-weight: 300;
    color: #666;
    margin: 0;
    margin: 0px 30px;
    padding: 8px 0px;
    font-size: 24px;
    line-height: 28px;
  }

  .horizontal-scroll {
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    width: calc(100% - 60px);
    overflow: auto;
    gap: 16px;
    padding-bottom: 16px;
    scroll-snap-type: x mandatory;
    scroll-padding: 30px;
    box-sizing: content-box;
  }

  #regionais {
    margin: 0px 30px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
</style>
