<template lang="">
  <div class="event-body">
    <div class="header" :style="headerStyle">
      <span class="title">{{ updatedEvent?.title }}</span>
      <span v-if="updatedEvent.speakers" class="speakers">{{stringStartDate}}</span>
    </div>
    <q-btn v-if="can('write_events')" style="margin-top: 16px; width: 66%;" @click="editEvent()" color="blue">
      <font-awesome-icon :icon="['fa', 'edit']" style="margin-right: 12px;"/>Editar evento
    </q-btn>
    <div class="event-section" id="description" v-if="updatedEvent.description">
      <h1>O Evento</h1>
      {{ updatedEvent.description }}
    </div>
    <div class="event-section" v-if="updatedEvent.speakers?.length > 0" id="speakers">
      <h1>Presenças Confirmadas</h1>
      <speaker style="width: 100%; margin-bottom: 14px;" v-for="speaker in updatedEvent.speakers" :key="speaker.name" :speaker="speaker" />
    </div>
    <div class="event-section" v-if="updatedEvent.registration_policy && updatedEvent.registration_policy != 'open'" id="pricing">
      <h1>Inscrições</h1>
      <div id="pricing-tier--container">
        <price-tier v-for="tier in tiers" :key="tier.id" :tier="tier" :hide-cta="getRegistration != null" :registration="getRegistration" />
      </div>
    </div>

    <div class="event-section" id="details">
      <h1>Detalhes</h1>
      <div class="container">
        <div class="item">
          <div class="icon">
            <font-awesome-icon v-if="updatedEvent.registration_policy == 'open'" :icon="['fa', 'door-open']"/>
            <font-awesome-icon v-if="updatedEvent.registration_policy == 'allows'" :icon="['fa', 'list']"/>
            <font-awesome-icon v-if="updatedEvent.registration_policy == 'requires'" :icon="['fa', 'door-closed']"/>
          </div>
          <div class="content">
            <span v-if="updatedEvent.registration_policy == 'open'">Esse evento é aberto e <b>não</b> oferece inscrição</span>
            <span v-if="updatedEvent.registration_policy == 'allows'">Esse evento é aberto e <b>oferece</b> inscrição</span>
            <span v-if="updatedEvent.registration_policy == 'requires'">Esse evento é fechado e exige inscrição</span>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <font-awesome-icon :icon="['fa', 'calendar']"/>
          </div>
          <div class="content">
            {{stringStartDate}}
          </div>
        </div>
        <div class="item" v-if="updatedEvent.venues && updatedEvent.venues.length > 0">
          <div class="icon">
            <font-awesome-icon :icon="['fa', 'location-dot']"/>
          </div>
          <div class="content">
            <div class="addressPart">
              {{ updatedEvent.venues[0].address }}
            </div>
            <div class="addressPart">
              {{ updatedEvent.venues[0].room }}
            </div>
            <GoogleMap :api-key="'AIzaSyB-H6QbUqGIVmJCURFjxHUekG1clUGo8dY'" :mapId="'59e3920765784629'" :center="geo" style="position: relative; left: -60px; width: calc(100% + 90px); padding: 10px 0px; height: 200px;" :zoom="15" :disableDefaultUi="true">
              <Marker :options="{ position: geo }" />
            </GoogleMap>
            <!-- <a :href="wazeLink" target="_blank">TESTE WAZE</a><br>
            <a :href="mapsLink" target="_blank">TESTE GMAPS</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Speaker from './Speaker.vue';
import { GoogleMap, Marker } from "vue3-google-map";
import { mapActions, mapGetters } from 'vuex';
import AuthMixin from '@/mixins/AuthMixin';
import PriceTier from '@/components/PriceTier.vue';

export default {
    name: "EventBody",
    components: { Speaker, GoogleMap, Marker, PriceTier },
    mixins: [
      AuthMixin,
    ],
    props: {
      event: Object,
    },
    methods: {
      ...mapActions(['hideOverlay']),
      ...mapActions('registrations', ['queryRegistrations']),
      editEvent() {
        this.$router.push('/admin/events/'+this.updatedEvent.id+'?backToEvent=true');
        this.hideOverlay();
      }
    },
    computed: {
      ...mapGetters('auth', ['authenticatedUser']),
      updatedEvent() {
        return this.$store.getters['events/getSingleEvent'](this.event.id);
      },
      geo() {
        return {
          lat: this.updatedEvent.venues[0].lat,
          lng: this.updatedEvent.venues[0].lng,
        }
      },
      tiers() {
        // eslint-disable-next-line
        return this.updatedEvent?.pricing_tiers?.sort((a,b) => a.order - b.order);
      },
      getRegistration() {
        return this.$store.getters['registrations/getAllRegistrations'].find((reg) => reg.pricing_tiers.events.id == this.updatedEvent.id && reg.status != 'rejected');
      },
      stringStartDate() {
        return new Date(this.updatedEvent.starts_at).toLocaleString('default', {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', 'hour': '2-digit', 'minute': '2-digit'});
      },
      wazeLink() {
        return "https://www.waze.com/ul?ll=" + this.updatedEvent.venues[0].lat + "%2C" + this.updatedEvent.venues[0].lng + "&navigate=yes&zoom=17";
      },
      mapsLink() {
        return "googlemaps://maps.google.com/maps?daddr=" + this.updatedEvent.venues[0].lat + "," + this.updatedEvent.venues[0].lng + "&amp;ll=," ;
      },
      headerStyle() {
        return `background-image: linear-gradient(0deg, rgba(0, 50, 100, 0.7), rgba(0, 50, 100, 0.7)) ,url('https://storage.cloud.google.com/umadc-digital-public/${this.updatedEvent?.image}')`;
      },
    },
    mounted() {
      if (this.event.registration_policy != 'open') {
        this.queryRegistrations();
      }
    },
}
</script>

<style lang="scss" scoped>
  .event-body {
    overflow-x: scroll;
    height: 100%;

    font-size: 14px;

    h1 {
      font-size: 24px;
      line-height: 24px;
      color: rgba(0,102,204,.66);
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;
    }

    .text-detail {
      color: #333;
    }

    .header {
      background-size: cover;
      background-position: center;
      height: 200px;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      text-align: left;
      padding: 24px;

      .title {
        font-weight: 700;
        font-size: 24px;
        letter-spacing: -1px;
        line-height: 28px;
      }

      .speakers {
        font-weight: 300;
        font-size: 12px;
        letter-spacing: -0.5px;
        line-height: 16px;
        
      }

      &:before {
        background-color: #000;
        width: 50%;
      }
    }
  }

  .event-section {
    padding: 8px 30px;
    text-align: left;
    line-break: normal;
    letter-spacing: -0.5px;
    font-weight: 300;
  }

  #details {
    .container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .item {
      display: flex;
      flex-direction: row;
      .icon {
      margin-right: 8px;
       color:  rgba(0, 102, 204, 0.66);
      }
      .content {
        flex-grow: 1;
      }

      .addressPart {
        line-height: 16px;
      }
    }
  }

  #pricing-tier--container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
</style>