<template lang="">
    <div>
        <iframe :src="confirmation.signedUrl" v-if="confirmation.signedUrl" style="height: 80vh;"/>
        <div class="button-container" v-if="confirmation.status != 'paid' && confirmation.status != 'rejected'">
            <q-btn label="Rejeitar" @click="denyConfirmation(confirmation.id)" color="red"/>
            <q-btn label="Aprovar" @click="approveConfirmation(confirmation.id)" color="green"/>
        </div>
    </div>
</template>
<script>
import NotifyMixin from '@/mixins/NotifyMixin';
import UpdatesConfirmations from '@/mixins/UpdatesConfirmations';

export default {
    props: ['confirmation'],
    mixins: [NotifyMixin, UpdatesConfirmations],
}
</script>
<style lang="scss" scoped>
iframe {
    width: 100%;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
</style>