<template lang="">
    <div class="form-container">
        <h1>{{ formTitle }}</h1>
        <q-form @submit="saveEvent()">
          <q-input :rules="[required]" v-model="event.title" label="Nome do evento"/>
          <q-input v-model="event.description" autogrow label="Descrição do evento" type="textarea"/>
          <q-input v-model="event.details" autogrow label="Detalhes do evento (um por linha)" type="textarea"/>
          <q-input :rules="[required, dateTime]" label="Data e hora de início" v-model="event.starts_at">
              <template v-slot:prepend>
                  <font-awesome-icon :icon="['fa', 'calendar']"/>
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                      <q-date mask="DD/MM/YYYY HH:mm:ss" v-model="event.starts_at">
                      <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                      </q-date>
                  </q-popup-proxy>
              </template>

              <template v-slot:append>
                  <font-awesome-icon :icon="['fa', 'clock']"/>
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                      <q-time v-model="event.starts_at" mask="DD/MM/YYYY HH:mm:ss" format24h>
                      <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                      </q-time>
                  </q-popup-proxy>
              </template>
          </q-input>

          <!-- <img style="width: 100%;" :src="bgImage" /> -->
          <input @change="newImageChanged($event)" id="fileInput" type="file"/>
          <q-select
              v-model="event.venues"
              use-input
              fill-input
              option-label="name"
              option-value="id"
              :options="allVenuesOptions"
              label="Local"
              multiple
              @filter="filterVenues"
              :rules="[required]"
          >
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-item-label>{{ scope.opt.name }}</q-item-label>
              </q-item-section>
              <q-item-section v-close-popup @click.stop="editItem('venues', scope.opt)" avatar>
                <font-awesome-icon color="grey" :icon="['fa', 'edit']"/>
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:no-option>
            <q-item>
              <q-item-section v-close-popup class="text-grey" @click="createNewItem('venues')">
                Sem resultados. Criar um novo?
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-select
          v-model="event.speakers"
          use-input
          fill-input
          option-label="name"
          option-value="id"
          :options="allSpeakersOptions"
          label="Participantes"
          @filter="filterSpeakers"
          multiple
        >
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-item-label>{{ scope.opt.name }}</q-item-label>
              </q-item-section>
              <q-item-section v-close-popup @click.stop="editItem('speakers', scope.opt)" avatar>
                <font-awesome-icon color="grey" :icon="['fa', 'edit']"/>
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:no-option>
            <q-item>
              <q-item-section v-close-popup class="text-grey" @click="createNewItem('speakers')">
                Sem resultados. Criar um novo?
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <q-select
          v-model="event.tags"
          use-input
          fill-input
          use-chips
          option-label="name"
          option-value="id"
          :options="allTagsOptions"
          @filter="filterTags"
          label="Tags"
          multiple
        > 
            <template v-slot:option="scope">
              <q-item v-bind="scope.itemProps">
                <q-item-section>
                  <q-item-label>{{ scope.opt.name }}</q-item-label>
                </q-item-section>
                <q-item-section v-close-popup @click.stop="editItem('tags', scope.opt)" avatar>
                  <font-awesome-icon color="grey" :icon="['fa', 'edit']"/>
                </q-item-section>
              </q-item>
            </template>
            <template v-slot:no-option>
            <q-item>
              <q-item-section v-close-popup class="text-grey" @click="createNewItem('tags')">
                Sem resultados. Criar um novo?
              </q-item-section>
            </q-item>
          </template>
        </q-select>
        
        <div class="action-buttons-container">
          <q-btn color="grey" @click="cancel()">&laquo; Voltar</q-btn>
          <q-btn color="blue" type="submit" :loading="isSaving">Salvar</q-btn>
        </div>
        
        <q-btn v-if="event.id" color="negative" @click="showConfirmationModal = ! showConfirmationModal">Apagar evento</q-btn>
        
        <q-dialog v-model="showConfirmationModal" persistent>
          <q-card>
            <q-card-section class="row items-center">
              <span class="q-ml-sm">Você tem certeza que deseja apagar <b>{{ this.event.title }}</b>? Essa ação é irreversível.</span>
            </q-card-section>

            <q-card-actions align="right">
              <q-btn flat label="Manter evento" color="primary" v-close-popup />
              <q-btn flat label="Apagar evento" color="primary" @click="deleteEvent()" />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </q-form>
    </div>
</template>
<script>
import Supabase from '@/supabaseInit';
import moment from 'moment';
import VenuesForm from '../venues/VenuesForm.vue';
import SpeakersForm from '../speakers/SpeakersForm.vue';
import TagsForm from '../tags/TagsForm.vue';
import { mapActions } from 'vuex';
import ValidationMixin from '@/mixins/ValidationMixin';
import UploadMixin from '@/mixins/UploadMixin';
import NotifyMixin from '@/mixins/NotifyMixin';

export default {
  mixins: [ValidationMixin, UploadMixin, NotifyMixin],
  data() {
    return {
      event: {
        title: null,
        description: null,
        starts_at: null,
        details: null,
        image: null,
        venues: [],
        speakers: [],
        tags: [],
      },
      allVenuesOptions: this.$store.getters['venues/getAllVenues'],
      allSpeakersOptions: this.$store.getters['speakers/getAllSpeakers'],
      allTagsOptions: this.$store.getters['tags/getAllTags'],
      newImage: null,
      fieldChanged: {},
      showConfirmationModal: false,
      isSaving: false,
    }
  },
  computed: {
    formTitle() {
      return this.$route.params.id ? 'Editar evento' : 'Criar evento';
    },
    allVenues() { return this.$store.getters['venues/getAllVenues']; },
    allSpeakers() { return this.$store.getters['speakers/getAllSpeakers']; },
    allTags() { return this.$store.getters['tags/getAllTags']; },
    bgImage() {
      if (!this.event.image)
        return null;

      if (this.event.image.startsWith('blob'))
        return this.event.image;

        return `https://storage.cloud.google.com/umadc-digital-public/${this.event.image}`;
    },
  },
  methods: {
    ...mapActions(['showOverlay']),
    ...mapActions('events', ['updateSingleEvent']),
    editItem(type, model) {
      let component = null;

      if (type == 'speakers') {
        component = SpeakersForm;
        // handler = (data) => this.event.speakers.push(data)
      }
      else if (type == 'tags') {
        component = TagsForm;
        // handler = (data) => this.event.tags.push(data)
      }
      else if (type == 'venues') {
        component = VenuesForm;
        // handler = (data) => this.event.venues.push(data)
      }

      this.showOverlay({ component: component, props: { 'model': model }, events: {} });
    },
    createNewItem(item, model) {
      let component = null;
      let handler = null;

      if (item == 'speakers') {
        component = SpeakersForm;
        handler = (data) => this.event.speakers.push(data)
      }
      else if (item == 'tags') {
        component = TagsForm;
        handler = (data) => this.event.tags.push(data)
      }
      else if (item == 'venues') {
        component = VenuesForm;
        handler = (data) => this.event.venues.push(data)
      }

      this.showOverlay({ component: component, props: { 'model': model }, events: { 'selected': handler } });
    },
    filterVenues(value, update) {
      if (value == '') {
        update(() => this.allVenuesOptions = this.allVenues);
        return;
      }
      update(() => this.allVenuesOptions = this.allVenues.filter((venue) => venue.name.toLowerCase().indexOf(value.toLowerCase()) > -1));
    },
    filterTags(value, update) {
      if (value == '') {
        update(() => this.allTagsOptions = this.allTags);
        return;
      }
      update(() => this.allTagsOptions = this.allTags.filter((tag) => tag.name.toLowerCase().indexOf(value.toLowerCase()) > -1));
    },
    filterSpeakers(value, update) {
      if (value == '') {
        update(() => this.allSpeakersOptions = this.allSpeakers);
        return;
      }
      update(() => this.allSpeakersOptions = this.allSpeakers.filter((speaker) => speaker.name.toLowerCase().indexOf(value.toLowerCase()) > -1));
    },
    async saveEvent() {
      this.isSaving = true;
      await this.withLoader(async () => {
        try {
          if (this.newImage) {
            // const format = this.newImage.name.split('.').slice(-1);

            if (this.event.image) {
              await this.deleteFile('eventImage', this.event.image);
            }

            const { path } = await this.uploadFile('eventImage', this.newImage);

            this.event.image = path;
          }

          const newEvent = await Supabase.from('events').upsert({
            id: this.event.id,
            title: this.event.title,
            description: this.event.description,
            details: this.event.details,
            image: this.event.image,
            starts_at: moment(this.event.starts_at, 'DD/MM/YYYY h:mm:ss').toISOString(),
          }).select();

          if (this.event.id) {
            await Supabase.from('event_tags').delete().eq('event_id', this.event.id);
            await Supabase.from('event_speakers').delete().eq('event_id', this.event.id);
            await Supabase.from('event_venues').delete().eq('event_id', this.event.id);
          }

          if (this.event.tags.length > 0) {
            await Supabase.from('event_tags').insert(
              this.event.tags.map((tag) => {
                return { "tag_id": tag.id, "event_id": this.event.id ?? newEvent.data[0].id };
              })
            )
          }

          if (this.event.speakers.length > 0) {
            await Supabase.from('event_speakers').insert(
              this.event.speakers.map((speaker) => {
                return { "speaker_id": speaker.id, "event_id": this.event.id ?? newEvent.data[0].id };
              })
            )
          }

          if (this.event.venues.length > 0) {
            await Supabase.from('event_venues').insert(
              this.event.venues.map((venue) => {
                return { "venue_id": venue.id, "event_id": this.event.id ?? newEvent.data[0].id };
              })
            )
          }

          this.isSaving = false;

          if (this.$route.query.backToEvent) {
            this.updateSingleEvent(newEvent.data[0].id);
            this.showOverlay({ 'component': 'EventBody', props: { 'event': newEvent.data[0] }, events: [] });
          }
        } catch (e) {
          console.error(e);
          this.isSaving = false;
        }
      });
    },
    newImageChanged(event) {
      if (!event) return;
      this.newImage = event.target.files[0];
      this.event.image = URL.createObjectURL(this.newImage);
    },
    async deleteEvent() {
      await Supabase.from('events').update({ deleted_at: moment().toISOString() }).eq('id', this.event.id);
      this.$router.push('/');
    },
    async cancel() {
      this.$router.back();
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      const { data } = await Supabase.from('events')
        .select('id,title,description,details,image,starts_at,tags(id,name,color),speakers(id,name,image,details),venues(id,name,address,image,lat,lng)')
        .is('tags.deleted_at', null)
        .is('speakers.deleted_at', null)
        .is('venues.deleted_at', null)
        .is('deleted_at', null)
        .eq('id', this.$route.params.id);

      this.event = data[0];

      this.event.starts_at = new Date(this.event.starts_at).toLocaleString().replace(',', '');
    }

    this.$store.dispatch('venues/queryAllVenues');
    this.$store.dispatch('speakers/queryAllSpeakers');
    this.$store.dispatch('tags/queryAllTags');
  },
}
</script>
<style lang="scss">
.form-container {
  padding: 30px;

  h1 {
    font-size: 24px;
    line-height: 24px;
    color: rgba(0, 102, 204, .66);
    font-weight: 700;
    margin: 0;
    margin-bottom: 10px;
  }

  .action-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 16px 0px;
  }
}
</style>